import React from 'react';

import {
    card,
    title as titleClass,
    number,
    label,
    dark,
    text as textClass,
} from './dashboard-panel-card.module.scss';

import Markdown from '../hoc/markdown';

interface IDashboardPanelCardProps {
    className?: string;
    title: string;
    count?: string | number;
    subtext?: string;
    text?: string;
    children?: React.ReactNode;
    theme?: 'light' | 'dark';
}

const DashboardPanelCard: React.FC<IDashboardPanelCardProps> = ({
    className = '',
    count,
    subtext,
    text,
    title,
    children,
    theme = 'light',
}) => {
    return (
        <div className={`${className} ${card} ${theme === 'dark' ? dark : ''}`}>
            <p className={titleClass}>{title}</p>
            {count && (
                <p className={number}>
                    <span>{count}</span>
                    {subtext && <span className={label}>{subtext}</span>}
                </p>
            )}
            {text && <Markdown className={textClass}>{text}</Markdown>}

            {children}
        </div>
    );
};

export default DashboardPanelCard;
