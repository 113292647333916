import React, { useEffect } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { IQueryAllResult } from '@alterpage/gatsby-plugin-alterpress-page-creator';

import {
    layout,
    grid,
    wrapper,
    buttonsWrapper,
    button,
} from './champ-client-dashboard.module.scss';
import { ISection } from '../../models/section.model';
import { IPage } from '../../models/page.model';

import useTranslations from '../../hooks/use-translations';
import { useClient } from '../../hooks/use-client';
import { useClientPermission } from '../../hooks/use-client-permission';
import { useNoPermissionModal } from '../../hooks/use-no-permission-modal';
import { usePagePathname } from '../../hooks/use-page-pathname';

import DashboardPanelCard from '../organisms/dashboard-panel-card';
import Section from '../hoc/section';
import Button from '../atoms/button';

interface IClientDashboardProps {
    section: ISection;
}

interface IClientDashboardQueryResult {
    allPage: IQueryAllResult<Pick<IPage, 'pathname' | 'type' | 'locale'>>;
}

const ChampClientDashboard: React.FC<IClientDashboardProps> = ({ section }) => {
    const {
        content: { buttons, texts },
    } = section;
    const [
        customTitleOne,
        customContentOne,
        customTitleTwo,
        customContentTwo,
        customTitleThree,
        customContentThree,
    ] = texts;
    const [buttonOne, buttonTwo, buttonThree] = buttons;

    const t = useTranslations('ChampClientDashboard');
    const client = useClient();
    const { allPage } = useStaticQuery<IClientDashboardQueryResult>(query);

    const projectsListingPage = usePagePathname(allPage, 'client-projects');
    const createProjectPage = usePagePathname(allPage, 'client-project-create');
    const offersListingPage = usePagePathname(allPage, 'client-advertisements');
    const createOfferPage = usePagePathname(allPage, 'client-advertisement-create');
    const candidatesPage = usePagePathname(allPage, 'client-candidate-search');

    const canProjects = useClientPermission(['can-projects']);
    const canAdvertisements = useClientPermission(['can-advertisements']);
    const { addNoPermissionPlanModal, addNoPermissionRoleModal } = useNoPermissionModal();

    useEffect(() => {
        if (!client.dashboard.data) return;
        client.dashboard.refetch();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Section
            className={layout}
            style={section.style}
            classes={{
                container: grid,
            }}
        >
            <div className={wrapper}>
                <DashboardPanelCard
                    title={t.offerTitle}
                    count={client.dashboard.data?.advertisements}
                    theme={'dark'}
                >
                    <div className={buttonsWrapper}>
                        {offersListingPage && (
                            <Button
                                className={button}
                                to={offersListingPage}
                                as={'link'}
                                stylePreset={'secondary'}
                            >
                                <span>{t.see}</span>
                            </Button>
                        )}
                        {createOfferPage && (
                            <Button
                                {...(canAdvertisements
                                    ? {
                                          to: createOfferPage,
                                          as: 'link',
                                      }
                                    : {
                                          onClick: addNoPermissionRoleModal,
                                      })}
                                stylePreset="secondary"
                                className={button}
                            >
                                <span>{t.addOffer}</span>
                            </Button>
                        )}
                    </div>
                </DashboardPanelCard>
                <DashboardPanelCard
                    title={t.projectsTitle}
                    count={client.dashboard.data?.projects.toString()}
                    theme={'dark'}
                >
                    <div className={buttonsWrapper}>
                        {projectsListingPage && (
                            <Button
                                className={button}
                                to={projectsListingPage}
                                as={'link'}
                                stylePreset={'secondary'}
                            >
                                <span>{t.see}</span>
                            </Button>
                        )}
                        {createProjectPage && (
                            <Button
                                {...(canProjects
                                    ? {
                                          to: createProjectPage,
                                          as: 'link',
                                      }
                                    : {
                                          onClick: addNoPermissionPlanModal,
                                      })}
                                stylePreset="secondary"
                                className={button}
                            >
                                <span>{t.newProject}</span>
                            </Button>
                        )}
                    </div>
                </DashboardPanelCard>
                <DashboardPanelCard
                    title={t.candidatesTitle}
                    count={client.dashboard.data?.candidates}
                    theme={'dark'}
                >
                    {candidatesPage && (
                        <Button
                            to={candidatesPage}
                            as="link"
                            className={button}
                            stylePreset="secondary"
                        >
                            <span>{t.searchCandidates}</span>
                        </Button>
                    )}
                </DashboardPanelCard>
                <DashboardPanelCard
                    title={customTitleOne || ''}
                    theme={'dark'}
                    text={customContentOne || ''}
                >
                    <Button
                        as="externalLink"
                        href={buttonOne?.url || ''}
                        className={button}
                        stylePreset="secondary"
                    >
                        <span>{t.see}</span>
                    </Button>
                </DashboardPanelCard>
                <DashboardPanelCard
                    title={customTitleTwo || ''}
                    theme={'dark'}
                    text={customContentTwo || ''}
                >
                    <Button
                        as="externalLink"
                        href={buttonTwo?.url || ''}
                        className={button}
                        stylePreset="secondary"
                    >
                        <span>{t.see}</span>
                    </Button>
                </DashboardPanelCard>
                <DashboardPanelCard
                    title={customTitleThree || ''}
                    theme={'dark'}
                    text={customContentThree || ''}
                >
                    <Button
                        as="externalLink"
                        href={buttonThree?.url || ''}
                        className={button}
                        stylePreset="secondary"
                    >
                        <span>{t.see}</span>
                    </Button>
                </DashboardPanelCard>
            </div>
        </Section>
    );
};

export const query = graphql`
    query {
        allPage(
            filter: {
                type: {
                    in: [
                        "client-projects"
                        "client-project-create"
                        "client-advertisements"
                        "client-advertisement-create"
                        "client-candidate-search"
                    ]
                }
            }
        ) {
            edges {
                node {
                    locale
                    pathname
                    type
                }
            }
        }
    }
`;

export default ChampClientDashboard;
